import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Highlight from "../components/common/highlight"
import Img from "gatsby-image"
import ScreenWidthMediaQueries from "../components/common/screen-width-media-queries"
import BodyContainer from "../components/common/body-container"

import styled from "styled-components"
import StyledLink from "../components/common/styled-link"
import ColumnLayout, { Column } from "../components/common/column-layout"
import { Spacer } from "."

export const blogQuery = graphql`
  {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            slug
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const MetaSection = styled.div`
  font-size: 0.8rem;
  padding: 4px 0;
  width: 100%;
`

const Tag = styled.span`
  padding-right: 8px;
`

const TextSection = styled.div`
  padding: 0;

  @media ${ScreenWidthMediaQueries.tablet} {
    padding-left: 24px;
  }
`

const Blog = ({ data }: any) => {
  const posts = data.allMarkdownRemark.edges.map(
    ({ node }: { node: any }) => node
  )

  return (
    <Layout section="blog">
      <SEO title="Blog" />
      <BodyContainer>
        <h1>Thoughts on Software, Travel, and More</h1>
        {posts.map((post: any) => {
          const {
            frontmatter: { slug, date, title, tags, featuredImage },
          } = post
          const image = featuredImage.childImageSharp.fluid

          return (
            <div key={slug}>
              <Spacer />
              <ColumnLayout>
                <Column flex={1}>
                  <Img
                    fluid={image}
                    style={{
                      borderRadius: 6,
                    }}
                  />
                  <MetaSection>
                    {tags.map((tag: string) => (
                      <Tag key={tag}>
                        <Highlight key={tag}>{tag}</Highlight>
                      </Tag>
                    ))}
                  </MetaSection>
                </Column>
                <Column flex={1}>
                  <TextSection>
                    <h2>
                      <StyledLink to={"/" + slug}>{title}</StyledLink>
                    </h2>

                    <p dangerouslySetInnerHTML={{ __html: post.excerpt }}></p>
                  </TextSection>
                </Column>
              </ColumnLayout>
            </div>
          )
        })}
        <Spacer />
      </BodyContainer>
    </Layout>
  )
}

export default Blog
