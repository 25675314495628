import { Link } from "gatsby"
import styled from "styled-components"
import React from "react"

const StyledLink = styled(props => <Link {...props} />)`
  color: ${props => props.theme.colors.primary};
  text-decoration: none;
  margin: ${props => props.margin || 0};
  border-bottom: 2px solid ${props => props.theme.colors.background};
  :hover {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }
  transition: border-color 0.1s ease-in;
  font-weight: 400;
`

export default StyledLink
